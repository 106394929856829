/* navbar.css */
.button-press2 {
    transition: transform 0.1s ease; /* Controls the speed of the animation */
}
/* Style the entire navigation bar */
.navbar-left {
    align-items: center; /* Vertically align links to the center */
    display: flex; /* Make the navbar items flex to align horizontally */
    gap: 20px; /* Add some space between links */
    position: fixed; /* Stick the navbar to the top */
    top: 0; /* Place it at the top of the viewport */
    left: 0; /* Align it to the left */
    right: 0; /* Align it to the right */
    background-color:#eae7dc; /* Set background color */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a shadow for elevation */
    z-index: 1000; /* Set a higher z-index to ensure it's above other content */
    overflow: hidden;
}

nav {
    padding: 10px 0; /* Add some padding to top and bottom */
    display: flex; /* Make the navbar items flex to align horizontally */
    justify-content: space-between; /* Spread items evenly horizontally */
    align-items: center; /* Vertically align items to center */
    background-color:#eae7dc; /* Set background color */

}

/* Style the logo */
.navbar-left img {
    height: 100px; /* Set the logo height */
    margin-left: 20px; /* Add some margin to the left */
    outline: none; /* Remove outline */
    align-items: center; /* Vertically align links to the center */
    gap: 20px; /* Add some space between links */
}

.navbar-left li {
    align-items: center; /* Vertically align links to the center */
    display: inline; /* Make the navbar items flex to align horizontally */
    font-size: 18px; /* Set the font size */
    padding: 10px 25px; /* Add padding to create a box around the text */
    border-radius: 5px; /* Add rounded corners */
    font-weight: 700;
}

.navbar-left a {
    text-decoration: none; /* Remove underlines from links */
    color: #333; /* Text color for links */
    outline: none; /* Remove outline */
}

.navbar-left a:hover {
    color: #f00606; /* Change color on hover */
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
}




.navbar-right {
    align-items: center; /* Vertically align links to the center */
    display: flex; /* Make the navbar items flex to align horizontally */
    gap: 20px; /* Add some space between links */
    position: fixed; /* Stick the navbar to the top */
    top: 0; /* Place it at the top of the viewport */
    right: 0; /* Align it to the right */
    background-color: #eae7dc; /* Set background color */
    z-index: 1000; /* Set a higher z-index to ensure it's above other content */
    margin-right: 25px;
}

.navbar-right {
    height: 100px; /* Set the logo height */
    margin-left: 20px; /* Add some margin to the left */
    outline: none; /* Remove outline */
    align-items: center; /* Vertically align links to the center */
    gap: 20px; /* Add some space between links */
}

.navbar-right li {
    align-items: center; /* Vertically align links to the center */
    display: inline; /* Make the navbar items flex to align horizontally */
    font-size: 18px; /* Set the font size */
    padding: 10px 25px; /* Add padding to create a box around the text */
    border-radius: 5px; /* Add rounded corners */
    font-weight: 700;
}
/* Navbar styling */
.menuicon-button {
    display: none;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin-top: 18px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .menuicon {
    height: 42px;
    width: 42px;
    color: black;
  }
  
  /* Dropdown menu styling */
  .nav-items {
    display: none;
    flex-direction: column;
    position: absolute;
    width:max-content;
    top: 60px;
    right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
  }
  
  /* Styling for each link */
  .nav-items a {
    text-decoration: none;
    color: #333;
    padding: 10px 15px;
    margin: 5px 0;
    border-radius: 6px;
    transition: background-color 0.2s;
  }
  
  /* Active link styling */
  .nav-items a.active {
    background-color: #007bff;
    color: white;
    
  }
  
  .nav-items a:hover {
    background-color: #f5f5f5;
  }
  
.navbar-right a {
    text-decoration: none; /* Remove underlines from links */
    color: #333; /* Text color for links */
    outline: none; /* Remove outline */
}

.navbar-right a:hover {
    color: #f00606; /* Change color on hover */
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
}

.signup-container {
    border-radius: 20px;
    border: 1px solid black;
  }

.Logo { 
    width: auto; 
    height: auto; 
    object-fit: contain; /* Ensures the image scales without distortion */
    vertical-align: middle;
}
@media (max-width: 900px) {
    .navbar-left img {
        height: 40px;
        margin-left: 10px;
    }

    .navbar-right { 
        height:fit-content;
        padding-top: 5px;
        
    }


    
    
}

@media (max-width: 768px) {
    .navbar-left img {
        height: 40px;
        margin-left: 10px;
    }

    .menuicon-button {
        display: flex;
    }

    .navtoggle {
        display: none;
        color: transparent;
    }

    .navbar-right { 
        padding: 0;
        top: -20px;
    }

    .navbar-right li{ 
        height: 15px;
        top: -20px;
    }

    .nav-items { 
        top: 80px !important;
        display: inline-block;
        padding: -10px;
    }

    .nav-items a.active {
        background-color: #007bff;
        color: white;
        padding: 8px 12px;
      }

}
@media (max-width: 480px) {
    .navbar-left img {
        height: 30px;
        margin-left: 5px;
    }

    .menuicon-button {
        display: flex;
    }

    .navtoggle {
        display: none;
        color: transparent;
    }
    .nav-items a.active {
        background-color: #007bff;
        color: white;
        padding: 10px 15px;
      }
    
}


/* Refine the right elements on small screens */
@media (max-width: 480px) {
    .navbar-right li {
        font-size: 12px; /* Further reduce font size */
        padding: 4px 10px; /* Compact padding for smallest screens */
    }

    .navbar-right {
        margin-right: 2px; /* Almost no right margin */
        height: 25px; /* Align height with smallest logo size */
    }

    .menuicon { 
        padding-bottom: 10px !important;
        
    }

}
@media (max-width: 310px) {
    .navbar-left img {
        height: 30px;
        margin-left: 5px; /* Adjust margin */
    }
    .navbar-right li {
        font-size: 10px; /* Reduce font size further */
        padding: 2px 5px; /* Reduce padding further */
    }
    .navbar-right {
        margin-right: 0; /* Remove right margin */
    }
    .nav-items {
        width: 100%; /* Ensure dropdown takes full width */
        max-width: 100%; /* Prevent it from going outside */
        scale: 50%;    
        display: inline-block;

    }

    .menuicon { 
        magin-top: 30px !important;
        scale: 90%;
    }

    .button-press { 
        display: inline-table !important;
    }
}




