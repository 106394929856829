/* General Styles */
body {
    font-family: 'Roboto', sans-serif;
    
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

/* Contact Form Container */
.contact-form {
    max-width: 300px;
    margin: 2rem auto;
    padding: 5rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title Styles */
.contact-form h4 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #333;
}

/* Input Styles */
.input-field {
    width: 50%; /* Adjusted width to make input fields shorter */
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 0 auto 1rem auto; /* Centering the input fields */
    font-size: 1rem;
    color: #000;
    background-color: #fff;
    display: block; /* Ensure they are block elements for centering */
}

/* Error Message Styles */
.error {
    color: red; /* Red text for error messages */
    margin-bottom: 1rem;
}

/* Button Styles */
/* Button Styles */
.contact-form button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background-color: #3f51b5; /* Normal background color */
    color: white; /* Normal text color */
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Add color transition */
}

.contact-form button:hover, .contact-form button:focus,.contact-form button:focus-visible, .contact-form button:focus-within {
    background-color: #303f9f; /* Hover background color */
    color: white; /* Ensure text color stays white on hover */
}

.contact-form button:active {
    background-color: #1c276c; /* Active background color */
    color: white; /* Ensure text color stays white on active */
}

.contact-form button:focus {
    outline: none; /* Remove default outline */
    background-color: #1c276c; /* Keep active color or any other */
    color: white; /* Ensure text color stays white on focus */
}



/* Snackbar Styles */
.snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .contact-form {
        padding: 1rem;
    }

    .input-field {
        width: 95%; /* Adjusted width to make input fields shorter */
    }
}

.MuiSnackbarContent-message, .MuiPaper-root, .MuiPaper-elevation, .MuiSnackbarContent-root {
    background-color: white;
}
