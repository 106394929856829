/* Footer CSS */


/* Main footer container */
.footer-background {
    color: #4056A1; /* Text color */
    padding: 20px 0; /* Add some padding to the top and bottom */
    text-align: center; /* Center-align text within the footer */
  }
  
  /* Footer content container */
  .footer-content {
    max-width: 3000px; /* Set a maximum width for the content */
    margin: 50px auto; /* Center the content horizontally */
    display: flex;
    flex-wrap: wrap; /* Wrap content on smaller screens */
    justify-content: space-between; /* Center-align content horizontally */
    align-items: flex-start; /* Align items at the top */
  }
  
/* Footer logo and description */
.footer-logo {
  flex: 4; /* Takes up 1/4 of the footer space */
  max-width: 100%; /* Adjust the maximum width of the logo container */
  text-align: left; /* Left-align text */
  margin-right: 0; /* No right margin */
  margin-bottom: 30px; /* Space between logo and quick links */
  margin-left: 10px; /* Move logo to the right by setting left margin to auto */
}

/* Responsive image */
.footer-logo img {
  max-width: 200px; /* Default size for larger screens */
  height: auto; /* Maintain aspect ratio */
  margin-left: 10px; /* Add right margin to move logo further to the right */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .footer-logo {
      flex: 1; /* Adjust flex size for smaller screens */
      text-align: center; /* Center-align for smaller screens */
      margin-right: 0; /* Remove right margin */
      margin-left: 0; /* Remove left margin */
  }

  .footer-logo img {
      max-width: 120px; /* Reduce logo size for tablets */
      margin-right: 0; /* Remove right margin on smaller screens */
  }
}

@media (max-width: 480px) {
  .footer-logo {
      margin-bottom: 20px; /* Reduce space below the logo */
  }

  .footer-logo img {
      max-width: 100px; /* Further reduce the logo size for mobile screens */
      margin-right: 0; /* No right margin for small screens */
  }
}


  
  /* Footer logo text */
  .footer-logo p {
    text-align: left; /* Left-align text */
    margin-top: 10px; /* Add spacing between the logo and text */
  }
  
  /* Footer navigation links (Quick Links) */
  .footer-links {
    flex: 1; /* Takes up 1/6 of the footer space */
    display: flex;
    flex-direction: column; /* Display links vertically */

  }
  
  .footer-links h2 {
    margin-top: 0; /* Remove default margin for the header */
    text-align: left; /* Left-align the header text */
    font-weight: bold;
  }
  
  .footer-links ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px; /* Increase spacing between the header and the first link */

  }
  
  .footer-links ul li {
    margin-bottom: 5px; /* Reduce spacing between list items */
    text-align: left; /* Left-align the list items */
  }
  
  .footer-links a {
    color: #333; /* Text color for links */
    text-decoration: none;
    transition: color 0.3s; /* Add a smooth color transition on hover */
  }
  
  .footer-links a:hover {
    color: #ff6b6b; /* Change link color on hover */
  }
  
  /* Footer contact links (Contact Us) */
  .footer-contact {
    flex: 1; /* Takes up 1/6 of the footer space */
    display: flex;
    flex-direction: column; /* Display links vertically */
    
  }
  
  .footer-contact h2 {
    margin-top: 0; /* Remove default margin for the header */
    text-align: left; /* Left-align the header text */
    font-weight: bold;
  }
  
  .footer-contact ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px; /* Increase spacing between the header and the first link */
  }
  
  .footer-contact ul li {
    margin-bottom: 5px; /* Reduce spacing between list items */
    text-align: left; /* Left-align the list items */
  }
  
  .footer-contact a {
    color: #333; /* Text color for links */
    text-decoration: none;
    transition: color 0.3s; /* Add a smooth color transition on hover */
  }
  
  .footer-contact a:hover {
    color: #ff6b6b; /* Change link color on hover */
  }
  
  /* Footer social media links */
  .footer-social ul {
    list-style-type: none;
    padding: 0;
    text-align: center; /* Center-align the list items horizontally */
    flex: 1; /* Takes up 1/3 of the footer space */
  }
  
  .footer-social ul li {
    display: inline-block; /* Display social media icons horizontally */
    margin-right: 20px; /* Increase spacing between icons */
  }
  
  /* Basic styling for social media icons */
  .footer-social i {
    font-size: 24px; /* Adjust the icon size as needed */
    color: #333; /* Icon color */
  }
  
  /* Copyright text */
  .footer-copyright {
    text-align: center; /* Center the copyright text */
    margin-top: 20px; /* Add spacing from the content above */
    margin-right:15px;
  }

.fa,
.fab,
.fas,
.far,
.fal {
    font-family: "Font Awesome 5 Free" !important; /* Adjust based on FA version */
    font-weight: 900; /* Adjust based on FA style (solid, regular, etc.) */
}

.Logo2 { 
  width: 600px; 
  height: 45rem; 
  object-fit: contain; /* Ensures the image scales without distortion */
  vertical-align: middle;
}

@media screen and (max-width: 650px) {
  .footerText { 
    display:none;
  }
}

@media (max-width: 400px) {
  .footerText { 
    display: none;
  }
 } 
@media (max-width: 480px) { 
  .footerText { 
    display: none;
} } 
@media (max-width: 768px) { 
  .footerText { 
    display: none;
  }
} 
@media (max-width: 900px) { 
  .footerText { 
    display: none;
  }
} 