/* projects.css */
body { 
    background-color: #eae7dc;
}
.projects-page {
    text-align: center;
    font-family: Arial, sans-serif;
}

.projects-title {
    font-size: 1.7em;
    font-weight: bold;
    padding-top: 210px;
}

.projects-subtitle {
    font-size: 1.3em;
    color: bl;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Set exactly two columns per row */
    gap: 25px;
}

@media (max-width: 768px) {
    /* Adjust for smaller screens to make cards stack in a single column */
    .projects-grid {
        grid-template-columns: 1fr;
        grid-gap: 60px;
    }
}

.project-card {
    background-color: #fff;
    margin: 50px;
    padding: 70px;
	margin-left:50px;
	margin-right:50px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
}

.project-image {
    background-color: #fff;
    padding-left: 15px;
    padding-top: 15px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 15px;
    max-block-size: 1200px;
    transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.project-image:hover {
    transform: scale(1.05); /* Small scale effect on hover */
}

/* Lightbox overlay for the enlarged image */
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Enlarged image styling */
.enlarged-image {
    max-width: 90%;
    max-height: 80vh;
    height: auto;
}



.project-info h2 {
	background-color: #fff;
    font-size: 1.6em;
    color: #333;
    margin-bottom: 10px;
}

.project-info {
	background-color: #fff;
	padding-left: 15px;
	padding-top: 15px;
	padding-bottom: 15px;
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 15px;
	justify-content: center;
	aspect-ratio:calc(9);
}

.project-info p {
	background-color: #fff;
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
}

.project-links {
	background-color: #fff;

    display: flex;
    gap: 10px;
}

.project-link {
	box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
    padding: 8px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    transition: background-color 0.2s;
}

.project-link:hover {
    background-color: #0056b3;
}


@media (max-width: 600px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }

    
    .projects-title {
        padding-top: 80px;
    }
}
@media (max-width: 900px) {
    .projects-title {
        font-size: 1.5em;
        margin-top: 130px;
    }

    .projects-subtitle {
        font-size: 1.2em;

    }

    .project-card {
        padding: 20px;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: 100%;
    }

    .project-image {
        width: 100%;
        height: 180px; /* Fixed height for consistency */
        object-fit: cover;
        margin-bottom: 10px;
        border-radius: 6px;
    }

    .project-info {
        padding: 15px;
        flex-grow: 1;
        text-align: center;
    }

    .project-info h2 {
        font-size: 1.4em;
    }

    .project-info p {
        font-size: 0.9em;
    }

    .project-link {
        padding: 6px 12px;
        font-size: 0.9em;
    }

    .enlarged-image {
        width: 90%;
        height: auto;
    }

    .projects-title {
        padding-top: 80px;
    }
}

/* Enhancements for small screens */
@media (max-width: 768px) {
    .projects-title {
        font-size: 1.5em;
        margin-top: 130px;
    }

    .projects-subtitle {
        font-size: 1.2em;

    }

    .project-card {
        padding: 20px;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: 100%;
    }

    .project-image {
        width: 100%;
        height: 180px; /* Fixed height for consistency */
        object-fit: cover;
        margin-bottom: 10px;
        border-radius: 6px;
    }

    .project-info {
        padding: 15px;
        flex-grow: 1;
        text-align: center;
    }

    .project-info h2 {
        font-size: 1.4em;
    }

    .project-info p {
        font-size: 0.9em;
    }

    .project-link {
        padding: 6px 12px;
        font-size: 0.9em;
    }

    .enlarged-image {
        width: 90%;
        height: auto;
    }

    .projects-title {
        padding-top: 80px;
    }
}

/* Enhancements for extra small screens */
@media (max-width: 480px) {
    .projects-title {
        font-size: 1.8em;

    }

    .projects-subtitle {
        font-size: 1.2em;
        margin: 15px 0;

    
    }

    .project-card {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .project-image {
        max-width: 100%;
        height: 160px; /* Fixed height for smaller screens */
        object-fit: cover;
        margin-bottom: 10px;
        border-radius: 6px;
    }

    .project-info h2 {
        font-size: 1.2em;
    }

    .project-info p {
        font-size: 0.8em;
    }

    .project-link {
        font-size: 0.8em;
        padding: 5px 10px;
    }

    .enlarged-image {
        width: 80%;
        height: auto;
    }

    .projects-title {
        padding-top: 180px;
    }
}

/* Ensure lightbox takes up full screen on mobile */
@media (max-width: 600px) {
    .lightbox {
        padding: 10px;
        align-items: flex-start;
    }

    .enlarged-image {
        max-width: 100%;
        height: auto;
    }

    
    .projects-title {
        padding-top: 80px;
    }
}